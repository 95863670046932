<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商品名称">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="所属商户">
        <SelectTdShop v-model="searchData.shopId" width="200px"></SelectTdShop>
        </el-form-item>
        <el-form-item label="卫生间">
        <el-select style="width: 200px" v-model="searchData.toiletType" placeholder="请选择" size="mini" clearable>
          <el-option label="独立卫生间" :value="1"> </el-option>
          <el-option label="无卫生间" :value="2"> </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="停车场">
        <el-select style="width: 200px" v-model="searchData.parkingType" placeholder="请选择" size="mini" clearable>
          <el-option label="付费停车" :value="1"> </el-option>
          <el-option label="免费停车" :value="2"> </el-option>
          <el-option label="无停车场" :value="2"> </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">待上架</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已上架</span>
        </el-badge>
      </div>
    </div>
    <!-- 新增 -->
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      icon="el-icon-plus"
      size="mini"
      plain
      @click="$router.push({ name: 'TdShopProductAdd', query: { shopId: searchData.shopId} })"
      >新增</el-button
    >
    <el-table :data="list">
      <el-table-column prop="name" label="商品名称" width="width"> </el-table-column>
      <el-table-column prop="shopName" label="店铺名称" width="width" min-width="100px">
        <template slot-scope="{ row }">{{ row?.shop?.name }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="width" min-width="100px">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">{{ row.statusDsc }}</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">{{ row.statusDsc }}</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="small">{{ row.statusDsc }}</el-tag>
        </template> 
      </el-table-column>
        <el-table-column prop="icon" label="封面图" width="width">
        <template slot-scope="{ row }">
          <el-image style="width: 40px; height: 40px; border-radius: 5px" :preview-src-list="[row.cover]" :src="row.cover" fit="cover">
            <div style="line-height: 40px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="toiletTypeDsc" label="卫生间" width="width" min-width="100px"></el-table-column>
      <el-table-column prop="parkingTypeDsc" label="停车场" width="width" min-width="100px"></el-table-column>
      <el-table-column prop="originalPrice" label="门市原价" width="width" min-width="150px"> </el-table-column>
      <el-table-column prop="price" label="商品售卖价格" width="width" min-width="150px"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="width" min-width="130px"> </el-table-column>
      <el-table-column label="操作" width="250px" fixed="right">
        <template slot-scope="{ row }">
          <el-link type="primary"  style="margin-right: 10px" :underline="false" @click="getStatus(row)">上下架</el-link>
          <el-link
            :disabled="row.status === 20"
            style="margin-right: 10px"
            type="primary"
            :underline="false"
            @click="$router.push({ name: 'TdShopProductEdit', params: { id: row.id } })"
            >编辑</el-link
          >
          <el-link :disabled="row.status === 20"  type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />


        <!-- 审核弹窗 -->
    <el-dialog title="上下架" :visible.sync="show_review" width="700px">
      <div class="review-box">
        <el-form ref="form" :model="changeStatusData" label-width="80px">
          <el-form-item label="状态">
            <el-radio-group v-model="changeStatusData.status">
              <el-radio :label="10">下架</el-radio>
              <el-radio :label="20">上架</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer">
        <el-button @click="show_review = false" size="small">取 消</el-button>
        <el-button type="primary" @click="changeStatus" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, addAPI, editAPI, getDetailAPI, changeStatusAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectTdShop from '@/views/td/shop-list/select-tdshop.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectTdShop },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      show_review: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        status: -1,
        shopId: null,
        name: '',
        toiletType: null, //  1:独立卫生间 2：无卫生间
        parkingType: null,//  停车场 0: 默认 1：付费停车 2：免费停车 3：无停车场
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },

  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
      // 审核弹窗
    getStatus(row) {
      this.changeStatusData = {
        id: row.id,
        status: row.status
      }
      this.show_review = true
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    handleClickTab(val) {
      if (this.searchData.status == val) return
      this.searchData.status = val
      this.getList()
    },
    async getList () {
      // 获取get 参数
      let params = { ...this.searchData }
      if (params.status === -1) {
        params.status = ''
      }
      const res = await getListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}
</style>
